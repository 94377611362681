import React from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import content from "./content.json";
import "./Lavalla.css";

function Lavalla() {
  console.log(content.carousel);
  const { carousel } = content;

  return (
    <div className="lavalla-section">
      <Carousel>
        {carousel.map((item, index) => (
          <Carousel.Item key={index} className={item.class}>
            <div className={item.social}>
              <a href="https://open.spotify.com/artist/3OElmmR1D18e4dPTISAF5r?si=AmsGagXXRbeXby8oSRLaMw" className="social-icon" target="_blank" rel="noreferrer">
                <i className="fa fa-spotify"></i><small> Spotify</small>
              </a><br></br>
              <a href="https://www.roopesalminenjakoirat.fi/" className="social-icon" target="_blank" rel="noreferrer">
                <i className="fa fa-share-alt"></i><small> www</small>
              </a>
            </div>

            <img
              src="https://roopesalminen.fi/img/carousel.png"
              alt={item.name}
              className="carousel-image"
            />
            <Carousel.Caption>
              <Container fluid>
                <Row className="justify-content-center">
                  <Col lg={8} xl={6}>
                    <h3>{item.name}</h3>
                    <p>{item.description}</p>
                  </Col>
                </Row>
              </Container>
            </Carousel.Caption>
            <p className="photo-credit">{item.credit}</p>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default Lavalla;
