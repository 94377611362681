import React, { useState } from "react";
import { Container, Form, Button, Card } from "react-bootstrap";
import { login } from "../utils/apiUtils";

function Login({updateUser}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = { email, password };
    login(data).then((result) => {
      if (result === "Error") {
        alert("There was an error, check console");
      } else {
        console.log(result);
        updateUser(result.username);
      }
    });
  };

  return (
    <Container className="justify-content-center align-items-center d-flex">
      <Card style={{ width: "100%", maxWidth: "400px" }} className="p-4">
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Login
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Login;
