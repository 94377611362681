import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-scroll';
import './Navigation.css';

function Navigation({ nav }) {
  return (
    <Navbar fixed="top" className={`transparent-nav ${nav}`}>
      <Nav className="mr-auto">
        <Nav.Link>
          <Link to="roope" smooth={false} duration={150}>Roope Salminen</Link>
        </Nav.Link>
        <Nav.Link>
          <Link to="lavalla" smooth={false} duration={150}>Roope lavalla</Link>
        </Nav.Link>
        <Nav.Link>
          <Link to="tilaa" smooth={false} duration={150}>Kyselyt</Link>
        </Nav.Link>
      </Nav>
    </Navbar>
  );
}

export default Navigation;