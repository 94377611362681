import axios from "axios";
const url = "https://koulutus.hookfinland.fi/api/";

export const login = async (data) => {
  try {
    const response = await axios.post(
      url + "login.php",
      data
    );
    return response.data
  } catch (error) {
    console.error("There was an error!", error);
    return "Error"
  }
};

export const getUser = async () => {
  try {
    const response = await axios.get(url + "api.php");
    console.log(response.data);
    const username = response.data.data[0].USERNAME; // Assuming the USERNAME is stored under the key 'USERNAME'
    return username; // This value will be returned by the async function
  } catch (error) {
    console.error("Error fetching data:", error);
    return "Error"; // This value will be returned by the async function in case of error
  }
};

export const getUsers = async () => {
  try {
    const response = await axios.get(url + "getusers.php");
    return response.data; // This value will be returned by the async function
  } catch (error) {
    console.error("Error fetching data:", error);
    return "Error"; // This value will be returned by the async function in case of error
  }
};

export const getContent = async () => {
  try {
    const response = await axios.get(url + "getcontent.php");
    return response.data; // This value will be returned by the async function
  } catch (error) {
    console.error("Error fetching data:", error);
    return "Error"; // This value will be returned by the async function in case of error
  }
};

export const updateContent = async (data) => {
  console.log("Sending a request to:");
  console.log(url + "content.php");
  try {
    const response = await axios.post(
      url + "content.php",
      data
    );
    console.log(response.data);
    return response.data
  } catch (error) {
    console.error("There was an error!", error);
    return "Error"
  }
};
