import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ContactForm from "./ContactForm";
import { Link } from 'react-scroll';
import "./Tilaa.css"

function Tilaa({ id, text }) {
  return (
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} className="text-center">
        <p>
          Etsitkö tapahtumaasi ammattimaista ja tilannetajuista juontajaa?
          Haluatko keventää tunnelmaa improtulla rap-kappaleella? Vai onko
          mielessä päivän huipentava bändikeikka? Roope esiintyy sujuvasti myös
          englanniksi. Lähetä kysely tai <Link to="yhteys"><strong>ota yhteyttä.</strong></Link>
        </p>
        <div className="tilauslomake">
          <ContactForm />
        </div>
        </Col>
        </Row>
      </Container>
  );
}

export default Tilaa;
