import "./App.css";
import React from "react";
import { Container } from "react-bootstrap";
import CookieBanner from "./components/CookieBanner";
import Navigation from "./components/Navigation";
import Hero from "./components/Hero";
import Roope from "./components/Roope";
import Lavalla from "./components/Lavalla";
import Tilaa from "./components/Tilaa";
import Yhteys from "./components/Yhteys";

function App() {

  return (
    <Container fluid className="App no-padding no-margin">
      <CookieBanner />
      <Navigation />
      <Container fluid className="section no-padding no-margin">
        <Hero />
      </Container>
      <Container fluid id="roope" className="section no-padding no-margin">
        <Roope />
      </Container>
      <Container fluid id="lavalla" className="section no-padding no-margin">
        <Lavalla />
      </Container>
      <Container fluid id="tilaa" className="section no-padding no-margin">
        <Tilaa />
      </Container>
      <Container fluid id="yhteys" className="section no-padding no-margin">
        <Yhteys />
      </Container>
    </Container>
  );
}

export default App;
