import "./App.css";
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Login from "./components/Login";
import Footer from "./components/Footer";
import { getUsers } from "./utils/apiUtils";
import Usertable from "./components/Usertable";

function Admin() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([
    { id: "Loading", username: "Loading", email: "Loading" },
  ]);
  useEffect(() => {
    let loggedIn = localStorage.getItem("loggedIn");
    setUser(loggedIn);
    console.log(loggedIn);
    if (loggedIn != null) {
      getUsers().then((result) => {
        if (result === "Error") {
          alert("There was an error, check console");
        } else {
          console.log(result);
          setUsers(result.data);
        }
      });
    }
  }, []);

  const updateUser = (user) => {
    localStorage.setItem("loggedIn", user);
    setUser(user);
    getUsers().then((result) => {
      if (result === "Error") {
        alert("There was an error, check console");
      } else {
        console.log(result);
        setUsers(result.data);
      }
    });
  };

  const handleHref = () => {
    navigate("/");
  };

  const handleSignOut = () => {
    localStorage.removeItem("loggedIn");
    setUser(null);
  };

  return (
    <Container
      fluid
      className="App no-padding no-margin mt-5"
      style={{ backgroundColor: "#000000" }}
    >
      <Container>
        {user == null && <Login updateUser={updateUser} />}
        {user != null && (
          <Row className="justify-content-center">
            <Col className="text-center vertical-center">
              <h2 className="admin-title">Actions:</h2>
              <Button className="d-block mx-auto my-2" onClick={handleHref}>
                Edit page
              </Button>
              <Button className="d-block mx-auto my-2" onClick={handleSignOut} variant="danger">
                Sign out
              </Button>
            </Col>
            <Col>
              <h2 className="admin-title">Users:</h2>
              <Usertable users={users} />
            </Col>
          </Row>
        )}
        <Footer username={user} />
      </Container>
    </Container>
  );
}

export default Admin;
