import React from "react";
import "./Hero.css";

function Hero({ nav }) {
  return (
    <div className="hero-section">
      <p className="photo-credit">Kuva: Julius Konttinen</p>
    </div>
  );
}

export default Hero;
