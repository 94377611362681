import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./CookieBanner.css";

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(true);

  const gtagScript = document.createElement("script");
  gtagScript.async = true;
  gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=G-4TD5RC0D18";
  document.head.appendChild(gtagScript);

  const handleAccept = () => {
    localStorage.setItem("consentGranted", "true");
    if (window.gtag) {
      console.log("Google Tag updated");
      window.gtag("consent", "update", {
        ad_user_data: "granted",
        ad_personalization: "granted",
        ad_storage: "granted",
        analytics_storage: "granted",
      });
      setShowBanner(false);
    }
  };

  useEffect(() => {
    console.log("Checking consent...");
    if (localStorage.getItem("consentGranted") === "true") {
      if (window.gtag) {
        console.log("Google Tag updated");
        window.gtag("consent", "update", {
          ad_user_data: "granted",
          ad_personalization: "granted",
          ad_storage: "granted",
          analytics_storage: "granted",
        });
      }
      setShowBanner(false);
    }
  }, []);

  if (!showBanner) return null;

  return (
    <div className="cookie-banner">
      <Container>
        <Row>
          <Col>
            <p>
              Käytämme evästeitä parantaaksemme kokemustasi verkkosivustollamme.
              Evästeet auttavat meitä ymmärtämään, kuinka vierailijat ovat
              käyttävät verkkosivustoamme. Ne keräävät nimettömiä tietoja ja
              antavat tietoa vierailijoiden määrästä, vierailluista sivuista
              sekä oleskelun kestosta.
            </p>
            <button onClick={handleAccept} className="btn-accept">
              Hyväksyn
            </button>
            <button
              onClick={() => setShowBanner(false)}
              className="btn-decline"
            >
              En hyväksy
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CookieBanner;
