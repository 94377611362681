import React from "react";
import { Container } from "react-bootstrap";
import "./Yhteys.css";

function Yhteys({ id, text }) {
  return (
    <div className="yhteys-section">
      <Container>
        <h3>Ossi Saarinen</h3>
        <p></p>
        <p>ossi[at]hookfinland.fi</p>
        <p>+358 40 737 0822</p>
        <Container className="mt-5">
          <small>© Hook Finland 2024</small><br></br>
          <div className="tietosuoja">
          <a href="https://roopesalminen.fi/img/Tietosuojaseloste.pdf" target="_blank" rel="noreferrer"><small>Tietosuojaseloste</small></a>
          </div>
        </Container>
      </Container>
    </div>
  );
}

export default Yhteys;
