import React from "react";
import { Table, Button } from "react-bootstrap";

export default function Usertable({ users }) {

  return (
    <Table striped bordered hover variant="dark" size="sm">
      <thead>
        <tr>
          <th>#</th>
          <th>Username</th>
          <th>Email</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <tr key={user.id}>
            <td>{user.id}</td>
            <td>{user.username}</td>
            <td>{user.email}</td>
            <td>Actions</td>
          </tr>
        ))}
        <tr>
        <td colSpan={4}><Button>Add user</Button></td>
        </tr>
      </tbody>
    </Table>
  );
}
