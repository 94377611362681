import React from "react";
import "./Footer.css"; // Ensure you have this CSS file in the correct location
import { SocialIcon } from "react-social-icons";
import { Container, Row } from "react-bootstrap";

const Footer = ({ username }) => {
  return (
    <footer className="footer">
      <Container>
        <Row className="justify-content-center mb-5">
          <SocialIcon
            url="https://twitter.com"
            bgColor="darkslategrey"
            style={{ height: 40, width: 40 }}
          />
          <SocialIcon
            url="https://www.instagram.com/roopesalminen/"
            bgColor="darkslategrey"
            style={{ height: 40, width: 40 }}
          />
        </Row>
        <Row>
          <p>
            © {new Date().getFullYear()} Your Company Name. All rights reserved.
          </p>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
