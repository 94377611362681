import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactForm() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tel: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch("https://roopesalminen.fi/api/contact.php", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      toast.success(data.message); // Show success toast
      setFormData({
        name: "",
        email: "",
        tel: "",
        message: "",
      });
    } catch (error) {
      toast.error("Viestin lähetys epäonnistui."); // Show error toast
    }
    setIsSubmitting(false);
  };

  return (
    <Container id="tilaa">
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Nimi</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Sähköposti</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Puhelinnumero</Form.Label>
          <Form.Control
            type="tel"
            name="tel"
            value={formData.tel}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Kerro tarkemmin tapahtumasta ja millaisen keikan haluaisit tilata
          </Form.Label>
          <Form.Control
            as="textarea"
            name="message"
            rows={3}
            value={formData.message}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          disabled={isSubmitting}
          className="mt-2"
          style={{
            backgroundColor: "darkslategrey",
            borderColor: "darkslategrey",
          }}
        >
          Lähetä kysely
        </Button>
      </Form>
      <div className="tietosuoja">
        <small>
          Lähettämällä lomakkeen hyväksyt{" "}
          <a
            href="https://roopesalminen.fi/img/Tietosuojaseloste.pdf"
            target="_blank"
            rel="noreferrer"
          >
            tietojesi käsittelemisen
          </a>
          .
        </small>
      </div>
      <ToastContainer /> {/* Add ToastContainer to render toasts */}
    </Container>
  );
}

export default ContactForm;
