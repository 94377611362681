import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Yhteys.css";

function Roope({ id, text }) {
  return (
    <div className="yhteys-section">
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} className="text-center">
            <h3>Roope Salminen</h3><br></br>
            <p>
              Roope Salminen on helsinkiläinen yrittäjä ja esiintyjä. Roope on
              tunnettu mm. yhtyeestään Roope Salminen & Koirat, lukuisten
              tv-ohjelmien juontamisesta ja hulvattomista improkeikoistaan.
              Roope rakastaa sarjakuvia ja Dungeons & Dragons -roolipeliä.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Roope;
